import { Helmet } from 'react-helmet-async'
import CreateProductInventoryView from '../sections/ProductInventory/CreateProductInventoryView'
import { MsalAuthenticationTemplate } from '@azure/msal-react'
import { InteractionType } from '@azure/msal-browser'

const CreateProductInventory = () => {
  return (
    <>
      <Helmet>
        <title>Agregar Producto | Sistema Planilla</title>
      </Helmet>
      
      <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
        <CreateProductInventoryView />
      </MsalAuthenticationTemplate>
    </>
  )
}

export default CreateProductInventory
