import React from 'react'
import Grid from '@mui/material/Grid'
import Skeleton from '@mui/material/Skeleton'

type ReportSkeletonsProps = {
  count?: number
}

const ReportSkeletons: React.FC<ReportSkeletonsProps> = ({ count = 4 }) => {
  return (
    <>
      {Array.from(new Array(count)).map((_, index) => (
        <Grid container spacing={1} sx={{ ml: 1 }} key={index}>
          <Grid item xs={12} md={6}>
            <Skeleton variant='text' width='60%' height={30} />
            <Skeleton variant='text' width='100%' />
            <Skeleton variant='rectangular' width='100%' height={118} />
          </Grid>
          <Grid item xs={12} md={6}>
            <Skeleton variant='text' width='100%' height={30} />
            <Skeleton variant='text' width='100%' />
            <Skeleton variant='rectangular' width='100%' height={118} />
          </Grid>
          <Grid item xs={12} md={12}>
            <Skeleton variant='text' width='100%' height={30} />
            <Skeleton variant='text' width='100%' />
            <Skeleton variant='rectangular' width='100%' height={118} />
          </Grid>
        </Grid>
      ))}
    </>
  )
}

export default ReportSkeletons
