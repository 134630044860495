import { memo, forwardRef, ReactNode, ForwardedRef } from 'react'
import Box from '@mui/material/Box'
import { SxProps } from '@mui/system'
import { StyledScrollbar, StyledRootScrollbar } from './styles'

// ----------------------------------------------------------------------

interface ScrollbarProps {
  children: ReactNode
  sx?: SxProps
  [key: string]: any
}

const Scrollbar = forwardRef(function Scrollbar(
  { children, sx, ...other }: ScrollbarProps,
  ref: ForwardedRef<HTMLDivElement>
) {
  const userAgent = typeof navigator === 'undefined' ? 'SSR' : navigator.userAgent

  const mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent)

  if (mobile) {
    return (
      <Box ref={ref} sx={{ overflow: 'auto', ...sx }} {...other}>
        {children}
      </Box>
    )
  }

  return (
    <StyledRootScrollbar>
      <StyledScrollbar
        scrollableNodeProps={{
          ref,
        }}
        clickOnTrack={false}
        sx={sx}
        {...other}
      >
        {children}
      </StyledScrollbar>
    </StyledRootScrollbar>
  )
})

export default memo(Scrollbar)