import React from 'react'
import Grid from '@mui/material/Grid'
import Skeleton from '@mui/material/Skeleton'

type ProductSkeletonsProps = {
  count?: number
}

const ProductSkeletons: React.FC<ProductSkeletonsProps> = ({ count = 5 }) => {
  return (
    <>
      {Array.from(new Array(count)).map((_, rowIndex) => (
        <Grid container key={rowIndex} alignItems="center" style={{ borderBottom: '1px solid rgba(224, 224, 224, 1)', padding: '10px 0' }}>
          <Grid item xs={12} sm={3}>
            <Skeleton variant="text" width="100%" />
          </Grid>
          <Grid item xs={6} sm={2}>
            <Skeleton variant="text" width="100%" />
          </Grid>
          <Grid item xs={6} sm={2}>
            <Skeleton variant="text" width="100%" />
          </Grid>
          <Grid item xs={6} sm={2}>
            <Skeleton variant="text" width="100%" />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Skeleton variant="text" width="100%" />
          </Grid>
        </Grid>
      ))}
    </>
  )
}

export default ProductSkeletons