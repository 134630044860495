import React from 'react'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import OutlinedInput from '@mui/material/OutlinedInput'
import InputAdornment from '@mui/material/InputAdornment'
import Tooltip from '@mui/material/Tooltip'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import SearchIcon from '@mui/icons-material/Search'
import Box from '@mui/material/Box'

interface AdditionalActionButton {
  action: () => void
  icon: React.ReactNode
  tooltipTitle: string
}

interface DataGridToolbarProps {
  numSelected?: number
  filterSearchText?: string
  onFilterSearchText?: (event: React.ChangeEvent<HTMLInputElement>) => void
  onEdit?: () => void
  onDelete?: () => void
  additionalActionButton?: AdditionalActionButton
}

const DataGridToolbar: React.FC<DataGridToolbarProps> = ({
  numSelected = 0,
  filterSearchText,
  onFilterSearchText,
  onEdit,
  onDelete,
  additionalActionButton,
}) => {
  return (
    <Toolbar
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        ...(numSelected > 0 && {
          bgcolor: (theme) => theme.palette.action.selected,
        }),
      }}
    >
      {numSelected > 0 ? (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {onEdit && (
            <Tooltip title='Editar' sx={{ pl: 0 }}>
              <IconButton size='small' onClick={onEdit}>
                <EditIcon />
              </IconButton>
            </Tooltip>
          )}
          {onDelete && (
            <Tooltip title='Eliminar'>
              <IconButton onClick={onDelete}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          )}
          {additionalActionButton && (
            <Tooltip title={additionalActionButton.tooltipTitle}>
              <IconButton onClick={additionalActionButton.action}>
                {additionalActionButton.icon}
              </IconButton>
            </Tooltip>
          )}
        </Box>
      ) : onFilterSearchText ? (
        <OutlinedInput
          value={filterSearchText}
          onChange={onFilterSearchText}
          placeholder='Buscar...'
          startAdornment={
            <InputAdornment position='start'>
              <SearchIcon />
            </InputAdornment>
          }
        />
      ) : null}
    </Toolbar>
  )
}

export default DataGridToolbar