import React from 'react'
import Grid from '@mui/material/Unstable_Grid2'
import Skeleton from '@mui/material/Skeleton'
import Card from '@mui/material/Card'
import Box from '@mui/material/Box'

type FarmCardSkeletonsProps = {
  count?: number
}

const FarmCardSkeletons: React.FC<FarmCardSkeletonsProps> = ({ count = 6 }) => {
  return (
    <>
      {Array.from(new Array(count)).map((_, index) => (
        <Grid xs={12} sm={6} md={3} key={index}>
          <Card>
            <Skeleton variant='rectangular' width='100%' height={118} />
            <Box sx={{ p: 2 }}>
              <Skeleton variant='text' />
              <Skeleton variant='text' width='60%' />
            </Box>
          </Card>
        </Grid>
      ))}
    </>
  )
}

export default FarmCardSkeletons
