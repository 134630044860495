import { useEffect, useState } from "react"
import { useMsal, useMsalAuthentication } from "@azure/msal-react"
import { InteractionType } from '@azure/msal-browser'
import { loginRequest } from "../authConfig"
import { axiosPrivate } from "../api/axios"
import { AxiosError } from 'axios'

const useAxiosPrivateLogin = () => {
    const { instance } = useMsal()
    const account = instance.getActiveAccount()
    const [isAxiosReady, setIsAxiosReady] = useState(false)
    const { result, error: msalError } = useMsalAuthentication(InteractionType.Popup, {
        ...loginRequest,
        account: account,
        // redirectUri: '/redirect'
    })    

    useEffect(() => {        

        if (msalError) {
            console.error("MSAL error:", msalError)        
            throw msalError
        }

        const getToken = async () => {            
            if (result?.accessToken) {                
                setIsAxiosReady(true)
                setupInterceptors(result.accessToken)                
            } else if (result && !result?.accessToken) {              
                try {
                    const response = await instance.acquireTokenSilent({
                        ...loginRequest,
                        account: account
                    })                   
                    setIsAxiosReady(true)
                    setupInterceptors(response.accessToken)                    
                } catch (e) {
                    console.error("Error al adquirir token de forma silenciosa", e)
                    setIsAxiosReady(false)
                }
            } else {                
                setIsAxiosReady(false)                
            }
        }

        const setupInterceptors = (accessToken) => {
            const requestIntercept = axiosPrivate.interceptors.request.use(
                async config => {
                    if (!config.headers['Authorization']) {
                        config.headers['Authorization'] = `Bearer ${accessToken}`
                    }
                    return config
                }, (error) => Promise.reject(error)
            )

            const responseIntercept = axiosPrivate.interceptors.response.use(
                response => response,
                async error => {                   
                    return Promise.reject(error)
                }
            )

            return () => {
                axiosPrivate.interceptors.request.eject(requestIntercept)
                axiosPrivate.interceptors.response.eject(responseIntercept)
            }
        }

        getToken()

        return () => {
            setIsAxiosReady(false)
        }
    }, [account, instance, result, msalError])

    return { axiosPrivate, isAxiosReady }
}

export default useAxiosPrivateLogin;
export { AxiosError }