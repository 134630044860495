export type Employee = {
    id: number
    firstName: string
    lastName: string
    paymentTypeDescription: string
}

export function getFullName(employee: Employee) {
    return `${employee.firstName} ${employee.lastName}`;
  }

  export type PaymentType = {
    id: number
    name: string
  }