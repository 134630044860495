import { forwardRef, ReactElement } from 'react'
import Box from '@mui/material/Box'
import Link from '@mui/material/Link'
import { SxProps } from '@mui/system'
import { NavLink } from 'react-router-dom'

interface LogoProps {
  disabledLink?: boolean
  sx?: SxProps
  [key: string]: any 
}

const Logo = forwardRef<HTMLDivElement, LogoProps>(
  ({ disabledLink = false, sx, ...other }, ref): ReactElement => {
    const logo = (
      <Box
        component="img"
        src="/logo.png"
        sx={{ width: 60, height: 60, cursor: 'pointer', borderRadius: '50%', ...sx }}
        ref={ref}
        {...other}
      />
    )

    if (disabledLink) {
      return logo
    }

    return (
      <Link component={NavLink} to="/" sx={{ display: 'contents' }}>
        {logo}
      </Link>
    )
  }
)

export default Logo