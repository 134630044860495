import { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Drawer from '@mui/material/Drawer'
import { alpha } from '@mui/material/styles'
import ListItemButton from '@mui/material/ListItemButton'
import Collapse from '@mui/material/Collapse'
import { useResponsive } from '../hooks/use-responsive'
import Logo from '../components/logo'
import Scrollbar from '../components/scrollbar'
import { NAV } from './config-layout'
import navConfig, { MenuConfig, MenuType } from './config-navigation'
import { useLocation, NavLink } from 'react-router-dom'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import { useAuth } from '../context/AuthProvider'

// ----------------------------------------------------------------------

interface NavProps {
  openNav: boolean
  onCloseNav: () => void
}

export default function Nav({ openNav, onCloseNav }: NavProps) {
  const { pathname } = useLocation()
  const { auth } = useAuth() // TODO: Remove
  const { user } = auth // TODO: Remove

  const stringsArray = ["jacovi80@gmail.com"] // TODO: Remove

  const upLg = useResponsive('up', 'lg')

  useEffect(() => {
    if (openNav) {
      onCloseNav()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])

  // TODO: Remove
  const renderMenu = (
    <Stack component="nav" spacing={0.5} sx={{ px: 2 }}>
      {navConfig.map((item) => {
        if (item.type === MenuType.Submenu) {
          const visibleChildren = item.children.filter(
            (child) => !(child.title === 'Planillas Pagadas' && stringsArray.includes(user?.email as string))
          )

          if (visibleChildren.length > 0) {
            return <NavItem key={item.title} item={{ ...item, children: visibleChildren }} />
          }
        }

        return item.title === 'Test' && stringsArray.includes(user?.email as string)
          ? null
          : <NavItem key={item.title} item={item} />
      })}
    </Stack>
  )

  // const renderMenu = (
  //   <Stack component="nav" spacing={0.5} sx={{ px: 2 }}>
  //     {navConfig.map((item) => (
  //       <NavItem key={item.title} item={item} />
  //     ))}
  //   </Stack>
  // )

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': {
          height: 1,
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      <Logo sx={{ mt: 3, ml: 4, mb: 4 }} />

      {renderMenu}
    </Scrollbar>
  )

  return (
    <Box
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV.WIDTH },
      }}
    >
      {upLg ? (
        <Box
          sx={{
            height: 1,
            position: 'fixed',
            width: NAV.WIDTH,
            borderRight: (theme) => `dashed 1px ${theme.palette.divider}`,
          }}
        >
          {renderContent}
        </Box>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          PaperProps={{
            sx: {
              width: NAV.WIDTH,
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  )
}

// ----------------------------------------------------------------------

interface NavItemProps {
  item: MenuConfig
}

function NavItem({ item }: NavItemProps) {
  const { pathname } = useLocation()
  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (item.type === MenuType.Submenu) {
      const isAnyChildActive = item.children.some(
        (child) => child.path === pathname
      )
      if (isAnyChildActive) {
        setOpen(true)
      }
    }
  }, [item, pathname])

  const handleClick = () => {
    setOpen(!open)
  }

  if (item.type === MenuType.Item) {
    const active = item.path === pathname

    return (
      <ListItemButton
        component={NavLink}
        to={item.path}
        sx={{
          minHeight: 44,
          borderRadius: 0.75,
          typography: 'body2',
          color: 'text.secondary',
          textTransform: 'capitalize',
          fontWeight: 'fontWeightMedium',
          ...(active && {
            color: 'primary.main',
            fontWeight: 'fontWeightSemiBold',
            bgcolor: (theme) => alpha(theme.palette.primary.main, 0.08),
            '&:hover': {
              bgcolor: (theme) => alpha(theme.palette.primary.main, 0.16),
            },
          }),
        }}
      >
        <Box component="span" sx={{ width: 24, height: 24, mr: 2 }}>
          {item.icon}
        </Box>

        <Box component="span" sx={{ flexGrow: 1 }}>
          {item.title}
        </Box>
      </ListItemButton>
    )
  }

  if (item.type === MenuType.Submenu) {
    return (
      <>
        <ListItemButton
          onClick={handleClick}
          sx={{
            minHeight: 44,
            borderRadius: 0.75,
            typography: 'body2',
            color: 'text.secondary',
            textTransform: 'capitalize',
            fontWeight: 'fontWeightMedium',
          }}
        >
          <Box component="span" sx={{ width: 24, height: 24, mr: 2 }}>
            {item.icon}
          </Box>

          <Box component="span" sx={{ flexGrow: 1 }}>
            {item.title}
          </Box>

          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>

        <Collapse in={open} timeout="auto" unmountOnExit>
          <Stack component="nav" spacing={0.5} sx={{ pl: 4 }}>
            {item.children.map((child) => (
              <NavItem key={child.title} item={child} />
            ))}
          </Stack>
        </Collapse>
      </>
    )
  }

  return null
}