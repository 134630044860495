import { Outlet } from 'react-router-dom'
import { useState, useEffect, FC } from 'react'
import useRefreshToken from '../../hooks/useRefreshToken'
import { useAuth } from '../../context/AuthProvider'
import Loading from "../Loading/Loading"
import DashboardLayout from '../../layouts'

const PersistLogin: FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const refresh = useRefreshToken()
  const { auth } = useAuth()

   // const effectRan = useRef<boolean>(false) //useRef is used to persist the value of a variable across renders without triggering a re-render when the value changes

  useEffect(() => {
    let isMounted = true

    const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms))


    // if (effectRan.current === true || process.env.NODE_ENV !== 'development') {  // Uncomment this line when enabling React.StrictMode
      const verifyRefreshToken = async () => {
        try {         
          await sleep(1000) //This allows the instance of the app to be mounted before the refresh token is verified
          await refresh()
        } catch (err) {
          console.error(err)
        } finally {
          isMounted && setIsLoading(false)
        }
      }

      !auth?.user ? verifyRefreshToken() : setIsLoading(false)
    // }

    return () => {
      isMounted = false
      // effectRan.current = true
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // useEffect(() => {
  //   console.log(`isLoading: ${isLoading}`)
  //   console.log(`aT: ${JSON.stringify(auth?.accessToken)}`)
  // }, [isLoading])

  return isLoading ? (
    <Loading />
  ) : (
    <>
      <DashboardLayout>
        <Outlet />
      </DashboardLayout>
    </>
  )
}

export default PersistLogin
