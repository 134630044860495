import axios, { AxiosInstance } from 'axios'
const BASE_URL: string | undefined = process.env.REACT_APP_SALARY_SYSTEM_API_URL

export default axios.create({
    baseURL: BASE_URL
}) as AxiosInstance

export const axiosPrivate: AxiosInstance = axios.create({
    baseURL: BASE_URL,
    headers: { 'Content-Type': 'application/json' },
    withCredentials: true
})

export type ApiError = {
    status?: number;
    title?: string;
    detail?: string;
  }