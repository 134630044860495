import { FC, useEffect, useState } from 'react'
import Container from '@mui/material/Container'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Unstable_Grid2'
import { axiosPrivate } from '../../api/axios'
import { endpoints } from '../../api/constants'
import { Tenant } from '../../context/AuthProvider'
import { AxiosError } from 'axios'
import useSnackbarMessages from '../../hooks/useSnackbarMessages'
import { GetPayrollDetailsResponse, JournalDailyWorkRequest, PayrollMaster } from '../../types/Payroll'
import ReportSkeletons from './ReportSkeletons'
import TextField from '@mui/material/TextField'
import { PaymentType, getFullName } from '../../types/Employee'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import TableBody from '@mui/material/TableBody'
import { fDateShort, formatFullDateWithDay } from '../../utils/format-time'
import Divider from '@mui/material/Divider'
import { fNumber } from '../../utils/format-number'
import PayrollFilters from './PayrollFilters'
import { Dayjs } from 'dayjs'
import { Farm } from '../../types/Farm' 
import { JournalDailyWorkPaymentStatus } from '../../types/Journal'
import Button from '@mui/material/Button'
import { useNavigate } from 'react-router-dom'
import Iconify from '../../components/iconify'

const MIN_LOADING_TIME = 500

interface PayrollDetailsViewProps {
  currentPayrollMaster: PayrollMaster
}

const PayrollDetailsView: FC<PayrollDetailsViewProps> = ({
  currentPayrollMaster
}) => {
  const [isLoading, setIsLoading] = useState(true)
  const { showError } = useSnackbarMessages()
  const [payrolDetails, setPayrolDetails] = useState<GetPayrollDetailsResponse>()
  const [selectedTenant, setSelectedTenant] = useState<Tenant | null>(null)
  const [selectedFarm, setSelectedFarm] = useState<Farm | null>(null)
  const [selectedJobTypeIds, setSelectedJobTypeIds] = useState<number[]>([])
  const [selectedPaymentType, setSelectedPaymentType] = useState<PaymentType | null>(null)
  const [selectedFromDate, setSelectedFromDate] = useState<Dayjs | null>(null)
  const [selectedToDate, setSelectedToDate] = useState<Dayjs | null>(null)
  const tenants: Tenant[] = (currentPayrollMaster?.payrolls.map(payroll => payroll.tenant).filter((tenant): tenant is Tenant => tenant !== undefined)) || []
  const navigate = useNavigate()

  const journalDailyWorkRequest: JournalDailyWorkRequest = {
    paymentStatus: JournalDailyWorkPaymentStatus.Paid,
    tenantId: selectedTenant ? selectedTenant.id : null,
    farmId: selectedFarm ? selectedFarm.id : null,
    employeeJobTypeIds: selectedJobTypeIds,
    paymentTypeId: selectedPaymentType ? selectedPaymentType.id : null,
    payrollMasterId: currentPayrollMaster.id,
    fromDate: selectedFromDate ? selectedFromDate.format('YYYY-MM-DD') : null,
    toDate: selectedToDate ? selectedToDate.format('YYYY-MM-DD') : null,
  }

  const getJournalDailyWorkReports = async (controller: AbortController) => {
    setIsLoading(true)
    try {
      const fetchStart = Date.now()

      const responsePromise = axiosPrivate.post(
        endpoints.getJournalDailyWorksForPayroll,
        journalDailyWorkRequest,
        { signal: controller.signal }
      )

      const [response] = await Promise.all([
        responsePromise,
        new Promise((resolve) =>
          setTimeout(resolve, MIN_LOADING_TIME - (Date.now() - fetchStart))
        ), // Ensure a minimum loading time
      ])

    setPayrolDetails(response.data)
    setIsLoading(false)

    } catch (err: any) {
      const error = err as AxiosError
      if (error.name !== 'CanceledError') {
        setIsLoading(false)
        showError('Error al obtener los datos de la planilla')
      }
    }
  }

  useEffect(() => {
    let isMounted = true
    const controller = new AbortController()

    if (isMounted) {
      getJournalDailyWorkReports(controller)
    }

    return () => {
      isMounted = false
      controller.abort()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedFromDate,
    selectedToDate,
    selectedTenant,
    selectedFarm,
    selectedJobTypeIds,
    selectedPaymentType
  ])

  return (
    <Container>
      <Stack
        sx={{ mb: 1 }}
        direction='row'
        alignItems='center'
        justifyContent='space-between'
        mb={5}
      >
        <Grid container sx={{ width: '100%' }}>
        <Grid
            xs={12}            
            sx={{
              mb: 2,
              display: 'flex',
              justifyContent: 'flex-start',
            }}
          >
            <Button
              onClick={() => { navigate(-1) } }
              variant='contained'
              color='inherit'
              startIcon={<Iconify icon='eva:arrow-back-fill' />}
            >
              Regresar
            </Button>
          </Grid>
          
          <Grid xs={12} md={8}>
            <Typography variant='h4'>Planilla del {formatFullDateWithDay(currentPayrollMaster.payrollDate)}</Typography>
          </Grid>          
        </Grid>
      </Stack>

      <PayrollFilters
        tenants={tenants}
        selectedTenant={selectedTenant}
        setSelectedTenant={setSelectedTenant}
        farms={payrolDetails?.farms || []}
        selectedFarm={selectedFarm}
        setSelectedFarm={setSelectedFarm}
        employeeJobTypes={payrolDetails?.employeeJobTypes || []}
        selectedJobTypeIds={selectedJobTypeIds}
        setSelectedJobTypeIds={setSelectedJobTypeIds}
        selectedPaymentType={selectedPaymentType}
        setSelectedPaymentType={setSelectedPaymentType}
        selectedFromDate={selectedFromDate}
        onFromDateChange={setSelectedFromDate}
        selectedToDate={selectedToDate}
        onToDateChange={setSelectedToDate}
      />

      <Card>
        <CardContent>
          {isLoading ? (
            <ReportSkeletons count={5} />
          ) : (
            payrolDetails?.employeeWorkDetails.map((report, index) => (
              <Grid xs={12} container spacing={1} sx={{ ml: 1 }} key={index}>
                <Grid xs={12}>
                  <Typography variant='h6'>
                    {getFullName(report.employee)}
                  </Typography>
                  <TableContainer component={Paper}>
                    <Table
                      sx={{ minWidth: { xs: 'auto', md: 650 } }}
                      size='small'
                      aria-label='a dense table'
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell>Fecha</TableCell>
                          <TableCell>Trabajo</TableCell>
                          <TableCell align='right'>Cantidad</TableCell>
                          <TableCell align='right'>Precio</TableCell>
                          <TableCell align='right'>SubTotal</TableCell>
                          <TableCell>Finca</TableCell>
                          <TableCell>Perfil</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {report.journalDailyWorkDetails.map((detail) => (
                          <TableRow
                            key={detail.id}
                            sx={{
                              '&:last-child td, &:last-child th': { border: 0 },
                            }}
                          >
                            <TableCell>
                              {fDateShort(
                                detail.journalDailyWork.journalDailyWorkDate
                              )}
                            </TableCell>
                            <TableCell>{detail.employeeJobType.name}</TableCell>
                            <TableCell align='right'>
                              {fNumber(detail.jobQuantity)}
                            </TableCell>
                            <TableCell align='right'>
                              {fNumber(detail.jobPrice)}
                            </TableCell>
                            <TableCell align='right'>
                              {fNumber(detail.dayPayment)}
                            </TableCell>
                            <TableCell>
                              {detail.journalDailyWork.farm.name}
                            </TableCell>
                            <TableCell>
                              {detail.journalDailyWork.tenant.name}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>

                  <Grid container spacing={1}>
                    <Grid xs={12} md={6}>
                      <Typography variant='body2' fontWeight='bold' sx={{ mx: 1 }}>
                        Total = {fNumber(report.totalPayment)}
                      </Typography>
                    </Grid>

                    <Grid xs={12} md={6}>
                      <Typography variant='body2' sx={{ mx: 1 }}>
                        Pago en {report.employee.paymentTypeDescription} {' '} {report.accountNumber ? `(${report.accountNumber})` : ''}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid xs={12} container sx={{ mx: 1, mt: 2, mb: 2 }}>
                    <Divider style={{ width: '100%' }} />
                  </Grid>
                </Grid>
              </Grid>
            ))
          )}

          <Grid xs={12} container sx={{ mx: 1, mt: 3, mb: 2 }}>
            <Divider style={{ width: '100%' }} />
          </Grid>

          <Grid xs={12} container spacing={1} sx={{ ml: 1 }}>
            <Grid xs={12}>
              <TextField
                label='Total Planilla'
                value={fNumber(payrolDetails?.totalPayrollPayment)}
                fullWidth
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>            
          </Grid>

          <Grid xs={12} container spacing={1} sx={{ ml: 1, mt:1 }}>
              {payrolDetails?.paymentTypeTotals.map((paymentTypeTotal) => (
                <Grid xs={12} md={6} key={paymentTypeTotal.paymentType} sx={{mt: 1}}>
                  <TextField
                    label={`Total ${paymentTypeTotal.paymentType}`}
                    value={fNumber(paymentTypeTotal.totalPayment)}
                    fullWidth
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
              ))}
            </Grid>

          <Grid xs={12} container sx={{ mx: 1, mt: 1, mb: 2 }}>
            <Divider style={{ width: '100%' }} />
          </Grid>
        </CardContent>
      </Card>
    </Container>
  )
}

export default PayrollDetailsView
