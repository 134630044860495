import { CircularProgress, CircularProgressProps } from '@mui/material'
import { styles } from "./LoadingStyles";

interface LoadingProps extends CircularProgressProps {
    centered?: boolean
}

const Loading: React.FC<LoadingProps> = ({ centered = true, ...props }) => {
  return (
    <CircularProgress sx={ centered ? styles.loadingContainer : undefined } 
    {...props} />
  )
}

export default Loading