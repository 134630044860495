import { FC, useState, useEffect } from 'react'
import Container from '@mui/material/Container'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Unstable_Grid2'
import Divider from '@mui/material/Divider'
import CardActions from '@mui/material/CardActions'
import Button from '@mui/material/Button'
import { endpoints } from '../../api/constants'
import { axiosPrivate, ApiError } from '../../api/axios'
import { ProductMeasure } from '../../types/Product'
import { AxiosError } from 'axios'
import useSnackbarMessages from '../../hooks/useSnackbarMessages'
import { useAuth } from '../../context/AuthProvider'
import Autocomplete from '@mui/material/Autocomplete'
import Loading from '../../components/Loading/Loading'
import { useNavigate } from 'react-router-dom'

type CreateProductRequest = {
  createdByUserEmail: string
  name: string
  unitPrice: number
  quantity: number
  description: string
  productMeasureId: number
  tenantId: number
}

const CreateProductInventoryView: FC = () => {
  const [productMeasures, setProductMeasures] = useState<ProductMeasure[]>([])
  const { showSuccess, showError } = useSnackbarMessages()
  const { auth } = useAuth()
  const { selectedTenant } = auth
  const [name, setName] = useState<string>('')
  const [unitPrice, setUnitPrice] = useState<number>(0)
  const [quantity, setQuantity] = useState<number>(0)
  const [description, setDescription] = useState<string>('')
  const [productMeasure, setProductMeasure] = useState<ProductMeasure | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const navigate = useNavigate()

  useEffect(() => {
    let isMounted = true
    const controller = new AbortController()

    const getProductMeasures = async () => {
      try {
        const response = await axiosPrivate.get(endpoints.getProductMeasures, {
          signal: controller.signal,
        })
        if (isMounted) {
          setProductMeasures(response.data)
        }
      } catch (err: any) {
        const error = err as AxiosError
        if (error.name === 'CanceledError') {
          //console.log('The request has been canceled')
        } else {
          showError('Error al cargar las medidas de los productos')
        }
      }
    }

    getProductMeasures()

    return () => {
      isMounted = false
      controller.abort()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()   

    const newProductInventory: CreateProductRequest = {      
      name,
      unitPrice,
      quantity,
      description,
      productMeasureId: productMeasure?.id!,
      tenantId: selectedTenant?.id || 0,
      createdByUserEmail: auth.user?.email || '', //TODO: Validate user
    }
   
    CreateProduct(newProductInventory)
  }  

  const CreateProduct = async (newProductInventory: CreateProductRequest) => {
    setIsLoading(true)
    const controller = new AbortController()

    const SendCreateProductRequest = async () => {    
        try {
          const response = await axiosPrivate.post(
            endpoints.createProduct,
            newProductInventory,
            { signal: controller.signal }
          )         
    
          if (response.status === 200) {
            showSuccess('Producto agregado correctamente!')            
            navigate('/inventario-productos')       
    
          }
        } catch (err: any) {
          const error = err as AxiosError<ApiError>
    
          if (error.name === 'CanceledError') {
            //console.log('The request has been canceled')
          } else {
            showError('Error crear el producto ' + error?.response?.data?.detail)
          }
        } finally {
          setIsLoading(false)
        }
      }

      SendCreateProductRequest()
  }



  const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    event.target.select()
  } 

  return (
    <Container>
      <Stack
        direction='row'
        alignItems='center'
        justifyContent='space-between'
        mb={5}
      >
        <Typography variant='h4'>Agregar producto</Typography>
      </Stack>

      <form autoComplete='off' noValidate onSubmit={handleSubmit}>
        <Card>
          <CardContent>
            <Grid container spacing={3}>
            {isLoading && <Loading centered={true}/>}
              <Grid xs={12} md={6}>
                <TextField
                  fullWidth
                  label='Nombre del Producto'
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </Grid>
              <Grid xs={12} md={6}>
                <Autocomplete
                  disablePortal
                  id='product-measure-select'
                  options={productMeasures}
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(
                    option: ProductMeasure,
                    value: ProductMeasure
                  ) => option.id === value.id}
                  value={productMeasure}
                  onChange={(event, newValue) => {
                    setProductMeasure(newValue)
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label='Medida del Producto' />
                  )}
                />
              </Grid>

              <Grid xs={12} md={6}>
                <TextField
                  fullWidth
                  label='Precio Unitario'
                  name='unitPrice'
                  type='number'
                  onChange={(e) => setUnitPrice(Number(e.target.value))}
                  required
                  value={unitPrice}
                  onFocus={handleFocus}
                />
              </Grid>
              <Grid xs={12} md={6}>
                <TextField
                  fullWidth
                  label='Cantidad'
                  name='quantity'
                  type='number'
                  onChange={(e) => setQuantity(Number(e.target.value))}
                  required
                  value={quantity}
                  onFocus={handleFocus}
                />
              </Grid>
              <Grid xs={12}>
                <TextField
                  fullWidth
                  label='Descripción'
                  name='description'
                  onChange={(e) => setDescription(e.target.value)}
                  required
                  value={description}
                  multiline
                  maxRows={4}
                />
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <CardActions sx={{ justifyContent: 'flex-end' }}>
            <Button type='submit' variant='contained' color='inherit'>
              Guardar
            </Button>
          </CardActions>
        </Card>
      </form>
    </Container>
  )
}

export default CreateProductInventoryView
