import { FC, ChangeEvent, useState, useEffect } from 'react'
import Container from '@mui/material/Container'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useAuth } from '../../context/AuthProvider'
import SearchBar from '../../components/searchbar'
import { Farm } from '../../types/Farm'
import Grid from '@mui/material/Unstable_Grid2'
import FarmCard from './FarmCard'
import useAxiosPrivate, { AxiosError } from '../../hooks/useAxiosPrivate'
import { endpoints } from '../../api/constants'
import FarmCardSkeletons from './FarmCardSkeletons'

const HomeView: FC = () => {
  const axiosPrivate = useAxiosPrivate()
  const { auth } = useAuth()
  const { selectedTenant } = auth
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [farms, setFarms] = useState<Farm[]>([])
  const [filteredFarms, setFilteredFarms] = useState<Farm[]>(farms)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  useEffect(() => {
    let isMounted = true
    const controller = new AbortController()
    setIsLoading(true)

    const getFarms = async () => {
      try {
        const response = await axiosPrivate.get(
          `${endpoints.getFarms}${selectedTenant?.id}`,
          { signal: controller.signal }
        )
        if (isMounted) {
          setFarms(response.data)
        }
      } catch (err: any) {
        const error = err as AxiosError
        if (error.name === 'CanceledError') {
          console.log('The request has been canceled')
        } else {
          // console.error(err) //TODO: show error
        }
      }
    }

    getFarms().finally(() => {
      if (isMounted) setIsLoading(false)
    })

    return () => {
      isMounted = false
      controller.abort()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTenant?.id])

  const handleSearchSelect = (event: ChangeEvent<{}>, farm: Farm) => {
    if (farm) {
      setSearchTerm(farm.name)
    } else {
      setSearchTerm('')
    }
  }

  useEffect(() => {
    if (searchTerm) {
      const results = farms.filter((farm) =>
        farm.name.toLowerCase().includes(searchTerm.toLowerCase())
      )
      setFilteredFarms(results)
    } else {
      setFilteredFarms(farms)
    }
  }, [searchTerm, farms])

  return (
    <Container>
      <Stack
        direction='row'
        alignItems='center'
        justifyContent='space-between'
        mb={5}
      >
        <Typography variant='h4'>{selectedTenant?.name}</Typography>
      </Stack>

      <Grid container mb={5}>
        <Grid xs={12} sm={8}>
          <SearchBar
            options={farms}
            getOptionLabel={(option: Farm) => option.name}
            isOptionEqualToValue={(option: Farm, value: Farm) =>
              option.id === value.id
            }
            placeholder='Buscar fincas...'
            onChange={handleSearchSelect}
          />
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        {isLoading ? (
          <FarmCardSkeletons count={5} />
        ) : (
          filteredFarms.map((farm: Farm) => (
            <FarmCard key={farm.id} farm={farm} />
          ))
        )}
      </Grid>
    </Container>
  )
}

export default HomeView
