import { FC, useEffect, useState } from 'react'
import Container from '@mui/material/Container'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import dayjs from 'dayjs'
import { ApiError, axiosPrivate } from '../../api/axios'
import { endpoints } from '../../api/constants'
import { useAuth } from '../../context/AuthProvider'
import { AxiosError } from 'axios'
import useSnackbarMessages from '../../hooks/useSnackbarMessages'
import Loading from '../../components/Loading/Loading'
import DailyWorkReportSteps from './DailyWorkReportSteps'
import { useDailyWorkReport } from '../../context/DailyWorkReportProvider'
import utc from 'dayjs/plugin/utc'
import { Farm } from '../../types/Farm'
import { JournalDailyWorkDetail } from '../../types/Journal'

dayjs.extend(utc)

type JournalDailyWorkDetailsModel = {
  employeeId: number
  employeeJobTypeId: number
  jobQuantity: number
}

type JournalDailyWorkProductDetailModel = {
  productId: number
  productQuantity: number    
}

type CreateJournalDailyWorkRequest = {
  createdByUserEmail: string
  farmId: number
  journalDailyWorkDate: string
  description: string | undefined
  tenantId: number
  journalDailyWorkDetails: JournalDailyWorkDetailsModel[]
  journalDailyWorkJobTypesIds: number[]
  journalDailyWorkProducts: JournalDailyWorkProductDetailModel[]
}

type CreateDailyWorkReportViewProps = {
  initialFarm?: Farm
  initialJournalDailyWorkDetails?: JournalDailyWorkDetail[]
}

const CreateDailyWorkReportView: FC<CreateDailyWorkReportViewProps> = ({
  initialFarm,
  initialJournalDailyWorkDetails
}) => {    
  const { auth } = useAuth()
  const { selectedTenant } = auth 
  const { showSuccess, showError } = useSnackbarMessages()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [activeStep, setActiveStep] = useState(0)   
  const {
    selectedFarm,
    setSelectedFarm,
    selectedFarmJobTypes,
    selectedDate,
    observations,
    journalDailyWorkProducts,
    journalDailyWorkDetails,
    setJournalDailyWorkDetails,
    setSelectedEmployees,
  } = useDailyWorkReport()


  useEffect(() => {
    if (initialFarm) {
      setSelectedFarm(initialFarm)
    }
    if (initialJournalDailyWorkDetails) {      
      setJournalDailyWorkDetails(initialJournalDailyWorkDetails)
      updateUniqueEmployees(initialJournalDailyWorkDetails)
      
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const updateUniqueEmployees = (updatedDetails: JournalDailyWorkDetail[]) => {
    const uniqueEmployeeIds = new Set()
    const uniqueEmployees = updatedDetails.filter(detail => {
      const isUnique = !uniqueEmployeeIds.has(detail.employee.id)
      uniqueEmployeeIds.add(detail.employee.id)
      return isUnique
    })
  
    setSelectedEmployees(uniqueEmployees.map(detail => detail.employee))
  }
  
  
  function sendReport() {
    setIsLoading(true)
    const controller = new AbortController()

    const createJournalDailyWorkRequest: CreateJournalDailyWorkRequest = {
      createdByUserEmail: auth.user?.email || '', //TODO: Validate user
      farmId: selectedFarm!.id,
      journalDailyWorkDate: selectedDate!.utc().toISOString(),
      description: observations,
      tenantId: selectedTenant!.id,
      journalDailyWorkDetails: journalDailyWorkDetails.map(detail => {
        return {
          employeeId: detail.employee!.id,
          employeeJobTypeId: detail.employeeJobType!.id,
          jobQuantity: detail.jobQuantity
        }
      }),
      journalDailyWorkJobTypesIds: selectedFarmJobTypes.map(jobType => jobType.id),
      journalDailyWorkProducts: journalDailyWorkProducts.map(detail => {
        return {
          productId: detail.product!.id,
          productQuantity: detail.productQuantity
        }
      })
    }

    const createJournal = async () => {     
      try {
        const response = await axiosPrivate.post(
          endpoints.createJournalDailyWork,
          createJournalDailyWorkRequest,
          { signal: controller.signal }
        )

        if (response.status === 200) {
          showSuccess('Reporte enviado!')
          setActiveStep((prevActiveStep) => prevActiveStep + 1)                  
        }
        
      } catch (err: any) {         
        const error = err as AxiosError<ApiError>

        if (error.name === 'CanceledError') {
          //console.log('The request has been canceled')
        } else {        
          showError('Error al enviar el reporte ' + error?.response?.data?.detail)
        }
      }
      finally {
        setIsLoading(false)
      }
    }

    createJournal()    
}

  return (
    <Container>
      <Stack
        direction='row'
        alignItems='center'
        justifyContent='space-between'
        mb={5}
      >
        <Typography variant='h4'>Reportar trabajo</Typography>
      </Stack>

      <DailyWorkReportSteps
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        lastStepButtonLabel='Enviar reporte'
        successMessage='Reporte enviado!'
        onSubmit={sendReport}
      />
      {isLoading && <Loading centered={true} />}
    </Container>
  )
}

export default CreateDailyWorkReportView
