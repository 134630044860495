import React, { FC } from 'react'
import { useLocation, Navigate, Outlet } from 'react-router-dom'
import { useAuth } from '../../context/AuthProvider'

interface RequireAuthProps {
  allowedRoles?: string[]
}

const RequireAuth: FC<RequireAuthProps> = ({ allowedRoles }) => {
    const { auth } = useAuth()
    const location = useLocation() 

    //TODO: Implement role-based authorization
    // return (
    //     auth?.roles?.find(role => allowedRoles?.includes(role))
    //         ? <Outlet />
    //         : auth?.accessToken //changed from user to accessToken to persist login after refresh
    //             ? <Navigate to="/unauthorized" state={{ from: location }} replace />
    //             : <Navigate to="/login" state={{ from: location }} replace />
    // )    

    return (
      auth?.user ? (
        <Outlet />
      ) : (
        <Navigate to="/login" state={{ from: location }} replace />
      )
    )
  }
  
  export default RequireAuth