import React, { ReactNode } from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'

interface ButtonProps {
  text: string
  action: () => void
}

interface DynamicDialogProps {
  openDialog: boolean
  dialogTitle: string
  onCloseAction: () => void
  buttonAccept?: ButtonProps
  buttonCancel?: ButtonProps
  children?: ReactNode
}

const DynamicDialog: React.FC<DynamicDialogProps> = ({
  openDialog,
  dialogTitle,
  onCloseAction,
  buttonAccept,
  buttonCancel,
  children
}) => {
  return (
    <Dialog
      open={openDialog}
      onClose={onCloseAction}
      aria-labelledby="generic-dialog-title"
      aria-describedby="generic-dialog-description"
    >
      <DialogTitle id="generic-dialog-title">
        {dialogTitle}
      </DialogTitle>
      <DialogContent>
        {children}
      </DialogContent>
      <DialogActions>
        {buttonCancel && (
          <Button onClick={buttonCancel.action}>
            {buttonCancel.text}
          </Button>
        )}
        {buttonAccept && (
          <Button onClick={buttonAccept.action} autoFocus>
            {buttonAccept.text}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}

export default DynamicDialog