import { Helmet } from 'react-helmet-async'
import HomeView from '../sections/home'
import { MsalAuthenticationTemplate } from '@azure/msal-react'
import { InteractionType } from '@azure/msal-browser'

const Home = () => {
  return (
    <>
      <Helmet>
        <title>Inicio | Sistema Planilla</title>
      </Helmet>

      <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
        <HomeView />
      </MsalAuthenticationTemplate>
    </>
  )
}

export default Home
