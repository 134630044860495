import { createContext, FC, useContext, useState, ReactNode, SetStateAction, Dispatch  } from "react"

export interface Tenant {
    name: string
    id: number
}

export interface User {
    userid: number
    username: string
    email: string
    imageurl?: string
}

export interface DecodedToken {
    userid: number
    username: string
    email: string
    tenants: string
    imageurl?: string
}


export interface AuthState {
    user?: User    
    tenants?: Tenant[]
    selectedTenant?: Tenant
    // roles?: string[] //TODO: Implement roles
}

interface AuthContextState {
    auth: AuthState
    setAuth: Dispatch<SetStateAction<AuthState>>
}

const AuthContext = createContext<AuthContextState | undefined>(undefined)
AuthContext.displayName = "AuthContext"

const AuthProvider: FC<{ children: ReactNode }> = ({ children }) => {
    const [auth, setAuth] = useState<AuthState>({})

    return (
        <AuthContext.Provider value={{ auth, setAuth }}>
            {children}
        </AuthContext.Provider>
    )
}

const useAuth = (): AuthContextState => {
    const context = useContext(AuthContext)
    if (!context) {
        throw new Error("useAuth must be used within an AuthProvider")
    }

    return context
}

export { AuthProvider, useAuth }