import { FC, useState } from 'react'
import Grid from '@mui/material/Unstable_Grid2'
import {
  DataGrid,
  GridColDef,
  GridRowId,
  GridActionsCellItem,
  GridRowParams,
  GridValueGetterParams,
} from '@mui/x-data-grid'
import {
  JournalDailyWorkProduct,
  ProductInventory,
} from '../../types/Product'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import { generateUniqueID } from '../../utils/common'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/DeleteOutlined'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'


enum EditableFields {
  ProductQuantity = 'productQuantity',
}

interface DailyWorkProductsProps {
  products: ProductInventory[]
  selectedProducts: ProductInventory[]
  setSelectedProducts: (products: ProductInventory[]) => void
  journalDailyWorkProducts: JournalDailyWorkProduct[]
  setJournalDailyWorkProducts: (journalDailyWorkProducts: JournalDailyWorkProduct[]) => void
  validateProductsRequiredValues: boolean
}

const DailyWorkProducts: FC<DailyWorkProductsProps> = ({
  products,
  selectedProducts,
  setSelectedProducts,
  journalDailyWorkProducts,
  setJournalDailyWorkProducts,
  validateProductsRequiredValues,
}) => {

  const initialEditRowState: JournalDailyWorkProduct = { // TODO: use reducer instead
    id: -1,
    product: {
      id: -1,
      name: '',
      unitPrice: 0,
      quantity: 0,
      description: '',
      productMeasure: { id: -1, name: '' },
      tenantId: -1,
    },
    productQuantity: 0,
    productPrice: 0,
  }

  const [selectedRows, setSelectedRows] = useState<GridRowId[]>([])
  const [editRow, setEditRow] = useState<JournalDailyWorkProduct>(initialEditRowState);
  const [openModal, setOpenModal] = useState(false)
  const [invalidProductQuantity, setInvalidProductQuantity] = useState(false)

  const handleProductSelection = ( _event: any,newValue: ProductInventory[] ) => {
    setSelectedProducts(newValue)

    const updatedJournalProducts = journalDailyWorkProducts.filter(
      (detail) => newValue.some((product) => product.id === detail.product.id)
    )

    newValue.forEach((product) => {
      if (
        !updatedJournalProducts.some(
          (detail) => detail.product.id === product.id
        )
      ) {
        updatedJournalProducts.push({
          id: generateUniqueID(),
          product: product,
          productQuantity: 0,
          productPrice: product.unitPrice,
        })
      }
    })

    setJournalDailyWorkProducts(updatedJournalProducts)
  }

  const handleEditRow = (id: GridRowId) => () => {
    const row = journalDailyWorkProducts.find(
      (row) => row.id === id
    ) as JournalDailyWorkProduct
    setEditRow(row)
    setOpenModal(true)
  }

  const handleDeleteRow = (id: GridRowId) => () => {
    const updatedDetails = journalDailyWorkProducts.filter(
      (row) => row.id !== id
    )
    setJournalDailyWorkProducts(updatedDetails)
    setSelectedProducts(updatedDetails.map((detail) => detail.product))
  }

  const handleCloseModal = () => {
    setOpenModal(false)
    setEditRow(initialEditRowState)
  }

  const handleSaveModal = () => {
    if (editRow && editRow.id !== -1) {

      if(editRow.productQuantity > editRow.product.quantity) {
        setInvalidProductQuantity(true)
        return
      }

      setInvalidProductQuantity(false)
      setJournalDailyWorkProducts(
        journalDailyWorkProducts.map((row) =>
          row.id === editRow.id ? editRow : row
        )
      )
    }
    handleCloseModal()
  }

  const handleEditjobQuantityChange = (prop: EditableFields) => (event: React.ChangeEvent<HTMLInputElement>) => {
    if (editRow && editRow.id !== -1) {
      setEditRow({ ...editRow!, [prop]: Number(event.target.value) })
    }
    }

  const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    event.target.select()
  }

  const validateProductWorkDetailsError = () => {
    if (!validateProductsRequiredValues) {
      return null
    }  

    const isInvalid = journalDailyWorkProducts.some(detail =>  detail.productQuantity === 0 )

    if (isInvalid) {
      return <Typography sx={{ml: 1}} color="error">Atención: Debe asignar una cantidad utilizada mayor a cero para todos los productos seleccionados.</Typography>
    }  

    return null
  }

  const columns: GridColDef[] = [
    {
      headerName: 'Producto',
      field: 'product',
      valueGetter: (
        params: GridValueGetterParams<JournalDailyWorkProduct>
      ) => params.row.product?.name,
      minWidth: 140,
      flex: 1,
      renderCell: (
        params // Text wrapping
      ) => (
        <div style={{ whiteSpace: 'normal', lineHeight: 'normal' }}>
          {params.value}
        </div>
      ),
    },
    {
      headerName: 'Cantidad',
      field: 'productQuantity',
      minWidth: 128,
      flex: 1,
    },
    {
      headerName: 'Medida',
      field: 'productMeasure',
      valueGetter: (
        params: GridValueGetterParams<JournalDailyWorkProduct>
      ) => params.row.product?.productMeasure?.name,
      minWidth: 140,
      flex: 1,
      renderCell: (
        params // Text wrapping
      ) => (
        <div style={{ whiteSpace: 'normal', lineHeight: 'normal' }}>
          {params.value}
        </div>
      ),
    },    
    {
      field: 'actions',
      type: 'actions',
      headerName: '',
      width: 55,
      getActions: (params: GridRowParams<JournalDailyWorkProduct>) => [
        <GridActionsCellItem
          sx={{ p: 0, m: 0 }}
          icon={<EditIcon />}
          label='Editar'
          className='textPrimary'
          onClick={handleEditRow(params.row.id)}
          color='inherit'
        />,
        <GridActionsCellItem
          sx={{ p: 0, m: 0 }}
          icon={<DeleteIcon />}
          label='Eliminar'
          onClick={handleDeleteRow(params.row.id)}
          color='inherit'
        />,
      ],
    },
  ]  

  return (
    <>
      <Grid xs={12} md={12}>
        <Autocomplete
          multiple
          id='combo-box-demo4'
          options={products}
          getOptionLabel={(productInventory) => productInventory.name}
          isOptionEqualToValue={(
            option: ProductInventory,
            value: ProductInventory
          ) => option.id === value.id}
          filterSelectedOptions={true}
          value={selectedProducts}
          onChange={handleProductSelection}
          renderInput={(params) => (
            <TextField
              {...params}
              label='Productos aplicados'             
            />
          )}
        />
      </Grid>
      {journalDailyWorkProducts.length > 0 && (
        <Grid xs={12} md={12}>
          {validateProductWorkDetailsError()}
          <DataGrid
            rows={journalDailyWorkProducts}
            columns={columns}
            disableRowSelectionOnClick
            density='compact'
            rowSelectionModel={selectedRows}
            onRowSelectionModelChange={(newSelection) => {
              setSelectedRows(newSelection)
            }}
          />
        </Grid>
      )}
      <Dialog open={openModal} onClose={handleCloseModal}>
        <DialogTitle>Producto utilizado</DialogTitle>
        <DialogContent>
          <TextField
            sx={{ mt: 2 }}
            size='small'
            id='outlined-read-only-productName'
            label='Producto'
            variant='filled'
            value={editRow?.product?.name}
            InputProps={{
              readOnly: true,
            }}
            fullWidth
            multiline
            maxRows={3}
          />
          {editRow?.product?.description && (
            <TextField
              sx={{ mt: 1 }}
              size='small'
              id='outlined-read-only-productDescription'
              label='Descripción'
              variant='filled'
              value={editRow?.product?.description}
              InputProps={{
                readOnly: true,
              }}
              fullWidth
              multiline
              maxRows={3}
            />
          )}
          <TextField
            sx={{ mt: 1 }}
            size='small'
            id='outlined-read-only-productMeasure'
            label='Medida'
            variant='filled'
            value={editRow?.product?.productMeasure?.name}
            InputProps={{
              readOnly: true,
            }}
            fullWidth
            multiline
            maxRows={3}
          />
          <TextField
            sx={{ mt: 1 }}
            size='small'
            id='outlined-read-only-productCurrentQuantity'
            label='Cantidad disponible'
            variant='filled'
            value={editRow?.product?.quantity}
            InputProps={{
              readOnly: true,
            }}
            fullWidth
          />
          <TextField
            sx={{ mt: 2 }}
            margin='dense'
            label='Cantidad utilizada'
            type='number'
            fullWidth
            variant='outlined'
            value={editRow ? editRow.productQuantity : ''}
            onChange={handleEditjobQuantityChange(
              EditableFields.ProductQuantity
            )}
            onFocus={handleFocus}
          />
         {invalidProductQuantity && <Typography sx={{ml: 1}} color="error">Atención: La cantidad utilizada del producto no puede ser mayor a la cantidad disponible.</Typography>}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal}>Cancelar</Button>
          <Button onClick={handleSaveModal}>Actualizar</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default DailyWorkProducts
