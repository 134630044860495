import React from 'react'
import { Dialog, DialogTitle, List, ListItem, ListItemText, DialogContent, Button } from '@mui/material'
import { Tenant } from '../../context/AuthProvider'

interface TenantSelectorProps {
  open: boolean
  tenants: Tenant[]
  onSelect: (tenant: Tenant) => void
}

const TenantSelector: React.FC<TenantSelectorProps> = ({ open, tenants, onSelect }) => {
  return (
    <Dialog onClose={() => {}} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle>Seleccione un perfil</DialogTitle>
      <DialogContent>
        <List>
          {tenants.map((tenant) => (
            <ListItem key={tenant.id} disablePadding>
              <Button 
                fullWidth
                onClick={() => onSelect(tenant)}                
              >
                <ListItemText primary={tenant.name} />
              </Button>
            </ListItem>
          ))}
        </List>
      </DialogContent>
    </Dialog>
  )
}

export default TenantSelector