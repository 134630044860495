import ThemeProvider from './theme'
import { useScrollToTop } from './hooks/use-scroll-to-top'
import Router from './routes/Router'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { MsalProvider } from "@azure/msal-react"
import { IPublicClientApplication } from "@azure/msal-browser"

type AppProps = {
  pca: IPublicClientApplication
}

function App({ pca }: AppProps) {
  useScrollToTop()

  return (
    <MsalProvider instance={pca}>
      <ThemeProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs}>        
            <Router />        
        </LocalizationProvider>
      </ThemeProvider>
    </MsalProvider>
  )
}

export default App
