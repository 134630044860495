import { useSnackbar } from 'notistack'

const useSnackbarMessages = () => {
  const { enqueueSnackbar } = useSnackbar()

  const showSuccess = (message: string) => {
    enqueueSnackbar(message, { variant: 'success' })
  }

  const showError = (message: string) => {
    enqueueSnackbar(message, { variant: 'error' })
  }
  
  //Other variants: 'default', 'success', 'error', 'warning', 'info'

  return { showSuccess, showError }
}

export default useSnackbarMessages