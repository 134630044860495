import { Helmet } from 'react-helmet-async'
import { MsalAuthenticationTemplate } from '@azure/msal-react'
import { InteractionType } from '@azure/msal-browser'
import PayrollDetailsView from '../sections/Payroll/PayrollDetailsView'
import { useLocation } from 'react-router-dom'

const PayrollDetails = () => {
  const location = useLocation();
  const { currentPayrollMaster } = location.state || {}  

  return (
    <>
      <Helmet>
        <title> Planilla Detalle | Sistema Planilla </title>
      </Helmet>

      <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
        <PayrollDetailsView  currentPayrollMaster={currentPayrollMaster}/>
      </MsalAuthenticationTemplate>
    </>
  )
}

export default PayrollDetails
