import { Helmet } from 'react-helmet-async'
import ProductInventoryView from '../sections/ProductInventory'
import { MsalAuthenticationTemplate } from '@azure/msal-react'
import { InteractionType } from '@azure/msal-browser'

const ProductInventory = () => {
  return (
    <>
      <Helmet>
        <title>Inventario Productos | Sistema Planilla</title>
      </Helmet>

      <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
        <ProductInventoryView />
      </MsalAuthenticationTemplate>      
    </>
  )
}

export default ProductInventory
