import { Helmet } from 'react-helmet-async'
import { MsalAuthenticationTemplate } from '@azure/msal-react'
import { InteractionType } from '@azure/msal-browser'
import CalculatePayrollView from '../sections/Payroll/CalculatePayrollView'

const CalculatePayroll = () => {
  return (
    <>
      <Helmet>
        <title> Calcular Planilla | Sistema Planilla </title>
      </Helmet>

      <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
        <CalculatePayrollView />
      </MsalAuthenticationTemplate>
    </>
  )
}

export default CalculatePayroll
