import { Helmet } from 'react-helmet-async';

import LoginView from '../sections/login/LoginView';

export default function LoginPage() {
    return (
      <>
        <Helmet>
          <title>Login | Sistema Planilla</title>
        </Helmet>
  
        <LoginView />
      </>
    );
  }