import { Helmet } from 'react-helmet-async'
import EditDailyWorkReportView from '../sections/DailyWorkReport/EditDailyWorkReportView'
import { DailyWorkReportProvider } from '../context/DailyWorkReportProvider'
import { useLocation } from 'react-router-dom'
import { JournalDailyWork } from '../types/Journal'
import { MsalAuthenticationTemplate } from '@azure/msal-react'
import { InteractionType } from '@azure/msal-browser'

const EditDailyWorkReport = () => {
  const location = useLocation()  
  const { currentReport } = location.state as { currentReport: JournalDailyWork } || {}
  
  return (
    <>
      <Helmet>
        <title>Actualizar Reporte Trabajo | Sistema Planilla</title>
      </Helmet>

      <DailyWorkReportProvider initialData={currentReport}>
        <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
          <EditDailyWorkReportView journalDailyWorkId={currentReport.id} />
        </MsalAuthenticationTemplate>
      </DailyWorkReportProvider>
    </>
  )
}

export default EditDailyWorkReport
