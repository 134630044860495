  
  export const styles = {
    loginContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100vh',
      textAlign: 'center'
    },
    appName: {
      marginBottom: 3
    },
    loginText: {
      marginBottom: 1
    }
}
    
    