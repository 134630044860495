import React, { forwardRef, ForwardedRef, ElementType } from 'react'
import { Icon } from '@iconify/react'
import Box from '@mui/material/Box'

interface IconifyProps {
  icon: ElementType | string
  width?: number
  sx?: Record<string, any>
  [key: string]: any 
}

const Iconify: React.FC<IconifyProps> = forwardRef(
  ({ icon, width = 20, sx, ...other }: IconifyProps, ref: ForwardedRef<any>) => (
    <Box
      ref={ref}
      component={Icon as ElementType}
      className="component-iconify"
      icon={icon}
      sx={{ width, height: width, ...sx }}
      {...other}
    />
  )
)

export default Iconify