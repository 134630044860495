import { FC, useEffect, useRef, useState } from "react"
import Container from '@mui/material/Container'
import Stack from '@mui/material/Stack'
import Typography from "@mui/material/Typography"
import Loading from '../../components/Loading/Loading'
import { AxiosError } from 'axios'
import { ApiError, axiosPrivate } from '../../api/axios'
import { endpoints } from '../../api/constants'
import useSnackbarMessages from '../../hooks/useSnackbarMessages'
import DailyWorkReportSteps from "./DailyWorkReportSteps"
import { useDailyWorkReport } from '../../context/DailyWorkReportProvider'
import { useAuth } from '../../context/AuthProvider'
import { Button } from "@mui/material"
import { NavLink } from "react-router-dom"

type JournalDailyWorkDetailsModel = {
  id: number
  employeeId: number
  employeeJobTypeId: number
  jobQuantity: number
}

type JournalDailyWorkProductDetailModel = {
  id: number
  productId: number
  productQuantity: number    
}

type UpdateJournalDailyWorkRequest = {
  id: number
  updatedByUserEmail: string
  farmId: number
  journalDailyWorkDate: string
  description: string | undefined  
  journalDailyWorkDetails: JournalDailyWorkDetailsModel[]
  journalDailyWorkJobTypesIds: number[]
  journalDailyWorkProducts: JournalDailyWorkProductDetailModel[]
}

interface EditDailyWorkReportViewProps {
  journalDailyWorkId: number
}

const EditDailyWorkReportView: FC<EditDailyWorkReportViewProps> = ({
  journalDailyWorkId
}) => {
  const { auth } = useAuth()
    const { showSuccess, showError } = useSnackbarMessages()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [activeStep, setActiveStep] = useState(0)
    const [displayCancelButton, setDisplayCancelButton] = useState<boolean>(true)
    const {
      selectedFarm,
      selectedFarmJobTypes,
      selectedDate,
      observations,
      journalDailyWorkProducts,
      journalDailyWorkDetails,
    } = useDailyWorkReport()
    
    const existingWorkDetailIdsRef = useRef<number[]>([])
    const existingProductDetailIdsRef = useRef<number[]>([])

    useEffect(() => {
      existingWorkDetailIdsRef.current = journalDailyWorkDetails.map(detail => detail.id)
      existingProductDetailIdsRef.current = journalDailyWorkProducts.map(detail => detail.id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function updatedReport() {
        setIsLoading(true)
        setDisplayCancelButton(false)
        const controller = new AbortController()
    
        const updateJournalDailyWorkRequest: UpdateJournalDailyWorkRequest = {
          id: journalDailyWorkId,
          updatedByUserEmail: auth.user?.email || '', //TODO: Validate user
          farmId: selectedFarm!.id,
          journalDailyWorkDate: selectedDate!.utc().toISOString(),
          description: observations,          
          journalDailyWorkDetails: journalDailyWorkDetails.map(detail => {

            const detailId = existingWorkDetailIdsRef.current.find(id => id === detail.id)         

            return {
              id: detailId || 0,
              employeeId: detail.employee!.id,
              employeeJobTypeId: detail.employeeJobType!.id,
              jobQuantity: Number(detail.jobQuantity)
            }
          }),
          journalDailyWorkJobTypesIds: selectedFarmJobTypes.map(jobType => jobType.id),
          journalDailyWorkProducts: journalDailyWorkProducts.map(detail => {

            const productDetailId = existingProductDetailIdsRef.current.find(id => id === detail.id)

            return {
              id: productDetailId || 0,
              productId: detail.product!.id,
              productQuantity: detail.productQuantity
            }
          })
        }
    
        const updateJournalReport = async () => {          
          try {
            const response = await axiosPrivate.put(
              endpoints.editJournalDailyWork,
              updateJournalDailyWorkRequest,
              { signal: controller.signal }
            )
    
            if (response.status === 200) {
              showSuccess('Reporte enviado!')
              setActiveStep((prevActiveStep) => prevActiveStep + 1)                  
            }
            
          } catch (err: any) {  
            console.log(err)              
            const error = err as AxiosError<ApiError>
    
            if (error.name === 'CanceledError') {
              //console.log('The request has been canceled')
            } else {        
              showError('Error al actualizar el reporte ' + error?.response?.data?.detail)
            }
          }
          finally {
            setIsLoading(false)
          }
        }
    
        updateJournalReport()    
    }
    
    return (
      <Container>
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='space-between'
          mb={5}
        >
          <Typography variant='h4'>Actualizar reporte de trabajo</Typography>
        </Stack>

        <DailyWorkReportSteps
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          lastStepButtonLabel='Actualizar reporte'
          successMessage='Reporte actualizado!'
          onSubmit={updatedReport}
        />
        {displayCancelButton && 
        <Button
          sx={{ mt: 6, ml: 6 }}
          variant='contained'
          color='error'
          component={NavLink}
          to='/reporte-trabajo'
        >
          Cancelar
        </Button>}
        {isLoading && <Loading centered={true} />}
      </Container>
    )
}

export default EditDailyWorkReportView