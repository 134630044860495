import { FC, useEffect } from 'react'
import { useAuth } from '../../context/AuthProvider'
import { useMsal } from '@azure/msal-react'
import { Box, Container, Typography } from '@mui/material'
import { styles } from './LoginStyles'
import Loading from '../Loading/Loading'

const LogOut: FC = () => {
  const { setAuth } = useAuth()
  const { instance } = useMsal()

  //uncomment the following line when enabling React.StrictMode
  // const effectRan = useRef<boolean>(false) //useRef is used to persist the value of a variable across renders without triggering a re-render when the value changes

  useEffect(() => {
    setAuth({})
    const logout = async () => {
      setAuth({})
      try {
        const logoutRequest = {
          account: instance.getActiveAccount(),
          postLogoutRedirectUri: '/login',
        }
        instance.logoutRedirect(logoutRequest)
      } catch (err) {
        console.error(err)
      }
    }

    // if (effectRan.current === true || process.env.NODE_ENV !== 'development') {
    logout()
    // }

    // return () => {
    //     effectRan.current = true
    //   }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Container sx={styles.loginContainer}>
      <Box>
        <Typography variant='h5' sx={styles.loginText}>
          Cerrando sesión...
        </Typography>
        <Loading centered={false} />
      </Box>
    </Container>
  )
}

export default LogOut
