import { Configuration, PopupRequest } from "@azure/msal-browser"

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
    auth: {
        clientId: "43604759-c5e1-4c75-98d9-33a5aa9c2d82",
        authority: "https://salarysystem.b2clogin.com/salarysystem.onmicrosoft.com/B2C_1_susi",
        redirectUri: "/",
        postLogoutRedirectUri: "/login",
        knownAuthorities: ["salarysystem.b2clogin.com"]
    },
    cache: {
        cacheLocation: 'sessionStorage', // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
        allowNativeBroker: false // Disables WAM Broker
    }
}

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: PopupRequest = {
    scopes: ["https://salarysystem.onmicrosoft.com/salarysystem-d-api/access_as_user"]
}

