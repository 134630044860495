import { useState } from 'react'
import Box from '@mui/material/Box'
import Avatar from '@mui/material/Avatar'
import Divider from '@mui/material/Divider'
import Popover from '@mui/material/Popover'
import { alpha } from '@mui/material/styles'
import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import { Tenant, useAuth } from '../context/AuthProvider'
import { useNavigate } from 'react-router-dom'
import Container from '@mui/material/Container'
import TenantSelector from '../sections/login/TenantSelector'
import { setLocalStorageItem } from '../utils/localStorageUtils'

interface MenuOption {
  label: string
  action?: () => void
}

export default function AccountPopover() {
  const [open, setOpen] = useState<HTMLElement | null>(null)
  const { auth } = useAuth()
  const { user, tenants, selectedTenant } = auth 
  const navigate = useNavigate()
  const [openModal, setOpenModal] = useState<boolean>(false)
  const { setAuth } = useAuth()

  const MENU_OPTIONS: MenuOption[] = [
    {
      label: 'Inicio',
      action: () => {
        handleClose()
        navigate('/')
      },
    },
    {
      label: 'Cambiar Perfil',
      action: () => {
        setOpenModal(true)
        handleClose()
      },
    },
  ]

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(event.currentTarget)
  }

  const handleClose = () => {
    setOpen(null)
  }

  const handleLogOut = () => {
    navigate('/logout')
  }

  const handleSetAuth = (tenant: Tenant) => {
    setAuth({ user, tenants, selectedTenant: tenant })
    setLocalStorageItem(process.env.REACT_APP_LOCAL_STORAGE_KEY_SELECTED_TENANT as string, JSON.stringify(tenant))    
}

const handleTenantSelect = (tenant: Tenant) => {   
  setOpenModal(false)
  handleSetAuth(tenant)
}


  return (
    <Container sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', pr: 0 }}>
      <IconButton
        onClick={handleOpen}
        sx={{
          width: 40,
          height: 40,
          background: (theme) => alpha(theme.palette.grey[500], 0.08),
          ...(open && {
            background: (theme) =>
              `linear-gradient(135deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 100%)`,
          }),
        }}
      >
        <Avatar
          src={user?.imageurl}
          alt={user?.username}
          sx={{
            width: 36,
            height: 36,
            border: (theme) => `solid 2px ${theme.palette.background.default}`,
          }}
        >
          {user?.username.charAt(0).toUpperCase()}
        </Avatar>
      </IconButton>

      <Popover
        open={!!open}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1,
            ml: 0.75,
            width: 200,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2 }}>
        <Typography variant="subtitle2" noWrap>
            {selectedTenant?.name}
          </Typography>
          <Typography variant="subtitle2" noWrap>
            {user?.username}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {user?.email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        {MENU_OPTIONS.map((option) => (
          <MenuItem key={option.label} onClick={option.action}>
            {option.label}
          </MenuItem>
        ))}

        <Divider sx={{ borderStyle: 'dashed', m: 0 }} />

        <MenuItem
          disableRipple
          disableTouchRipple
          onClick={handleLogOut}
          sx={{ typography: 'body2', color: 'error.main', py: 1.5 }}
        >
          Salir
        </MenuItem>
      </Popover>
      <Container>
       <TenantSelector
          open={openModal}
          tenants={tenants || []}
          onSelect={handleTenantSelect}
        />  
       </Container>
    </Container>
  )
}
